<template>
  <div class="tree-select">

    <van-sidebar v-model="activeKey">
      <van-sidebar-item v-for="(item,index) in busgrp" :title="item.busgrp" :info="item.counts == '' ? '' : item.counts"/>
    </van-sidebar>

    <van-index-bar class="index-bar" :sticky=false :index-list="indexList">
      <van-index-anchor v-for="(item,index) in person" :key="index">
        <span class="indexWord">{{ item.letter }}</span>
        <van-cell v-for="(item1,index1) in item.child" :title="item1.userName" @click="to(item1.userNo)">
          <template slot="icon">
            <van-image width="30px" height="30px" style="margin-right: 10px"
                       :src="require('@/assets/busgrp/'+item1.userName+ '.png')"/>
            <span class="number" v-if="item1.counts != ''">{{ item1.counts }}</span>
          </template>
        </van-cell>
      </van-index-anchor>
    </van-index-bar>
  </div>
</template>

<script>
import {IndexBar, IndexAnchor, Toast} from 'vant';
import {getDigitalBusgrp} from '@/api/digital';
import {getDigitalPerson} from '@/api/digital'

export default {
  name: "TreeSelect",
  data() {
    return {
      activeKey: 0,
      busgrp: [],
      person: [],
      indexList: [],
      isActive: false,
      isActive1: false,
      isActive2: false
    };
  },
  watch: {
    //监听公司名头
    'activeKey': function () {
      this.indexList = []
      getDigitalPerson({busgrp: this.busgrp[this.activeKey].busgrp}).then(res => {
        console.log(res)
        if (res.length > 0) {
          this.person = res
          for (var i = 0; i < res.length; i++) {
            this.indexList.push(res[i].letter)
          }
          // Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
  },
  mounted() {
    getDigitalBusgrp({}).then(res => {
      console.log(res)
      if (res.length > 0) {
        this.busgrp = res
        setTimeout(() => {
          // 需要等待的操作
          getDigitalPerson({busgrp: res[0].busgrp}).then(res1 => {
            this.indexList = []
            console.log(res1)
            if (res1.length > 0) {
              this.person = res1
              for (var i = 0; i < res1.length; i++) {
                this.indexList.push(res1[i].letter)
              }
              console.log(this.indexList)
              // Toast.success('加载成功');
            } else {
              Toast.success('暂无数据');
            }
            Toast.success('加载成功');
          }).catch(err => {
            console.log(err)
            Toast.fail('加载失败');
          })
        }, 100)
      } else {
        Toast.success('暂无数据');
      }
    }).catch(err => {
      console.log(err)
      Toast.fail('加载失败');
    })

  },
  methods: {
    to(i){
      this.$router.push({path: '/DigitalGrowDetail/'+i})
    }
  }
}
</script>

<style scoped>
.tree-select {
  background-color: #FFFFFF;
  color: #000000;
  display: flex;
  height: 1000px;
}

.tree-select .special {
  margin-top: 10px;
}

.tree-select .active {
  background-color: #FFFFFF;
  border-color: #3d9dc4;
  color: #000000;
  width: 83%;
  height: 25px;
  font-size: 11px;
  line-height: inherit;
  letter-spacing: 1px;
  border-radius: 5px
}

.tree-select .active1 {
  background-color: #FFFFFF;
  border-color: #FA9128;
  color: #FA9128;
  width: 83%;
  height: 25px;
  font-size: 11px;
  line-height: inherit;
  letter-spacing: 1px;
  border-radius: 5px
}

.tree-select .index-bar {
  width: 100%;
  position: relative;
  text-align: left
}

.tree-select .van-sidebar-item {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 20px 12px;
  overflow: hidden;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
  background-color: #FFFFFF;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.tree-select .van-sidebar-item--select::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 36px;
  background-color: #FA9128;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.tree-select .van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  background-color: #FFFFFF;
  text-decoration: none;
}

.tree-select .van-cell::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 0px solid #ebedf0;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
}

.tree-select .number {
  position: absolute;
  left: 12%;
  top: 4px;
  background-color: red;
  color: #fff;
  line-height: 14px;
  width: 15px;
  border-radius: 12px;
  text-align: center;
  font-size: 11px;
}

</style>


<style>
.tree-select .van-index-anchor {
  z-index: 1;
  box-sizing: border-box;
  padding: 0 16px;
  color: #e6a23c;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  background-color: transparent;
  margin-top: 10px;
}

</style>